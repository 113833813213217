<template>
  
  <v-card style="height:100%" :class="{'dark-mode':$store.state.NightMode.sideNav == 'dark-mode','light-mode':$store.state.NightMode.sideNav == 'light-mode'}">
    <v-toolbar flat :class="{'dark-mode':$store.state.NightMode.sideNav == 'dark-mode','light-mode':$store.state.NightMode.sideNav == 'light-mode', 'hidden-lg-and-up': 'hidden-lg-and-up'}">
      <v-toolbar-title style="width: 260px" class="ml-0">
        <!--<v-toolbar-side-icon @click.stop="drawer = !drawer"></v-toolbar-side-icon>-->
        <span> <span style="color: orange;">MyWallet</span>Watcher</span>
      </v-toolbar-title>
    </v-toolbar>

    <!-- Side Navigation tabs -->
    <v-tabs grow color="SideNavTab">
      <v-tabs-slider color="orange"></v-tabs-slider>

      <!--Main menu-->
      <v-tab :href="'#MainMenu'" :class="{'dark-mode':$store.state.NightMode.sideNav == 'dark-mode','light-mode':$store.state.NightMode.sideNav == 'light-mode'}">
        <!--<v-tooltip bottom>
          <v-badge slot="activator" color="green" overlap>
            <span slot="badge" v-if="mainMenuBadge !== ''"> {{ mainMenuBadge }} </span>
            <v-icon>home</v-icon>
          </v-badge>
          <span>Home</span>
        </v-tooltip>-->
      </v-tab>
      <!--End Of Main Menu-->

      <!--Extensions-->
      <!--<v-tab :href="'#Extensions'" :class="{'dark-mode':$store.state.NightMode.sideNav == 'dark-mode','light-mode':$store.state.NightMode.sideNav == 'light-mode'}">
        <v-tooltip bottom>
          <v-badge slot="activator" color="red" overlap>
            <span slot="badge" v-if="extensionsBadge !== ''"> {{ extensionsBadge }} </span>
            <v-icon>extension</v-icon>
          </v-badge>
          <span> Extension </span>
        </v-tooltip>
      </v-tab>-->
      <!--/Extensions-->

      <!--Budget Data-->
      <!--<v-tab :href="'#BudgetData'" :class="{'dark-mode':$store.state.NightMode.sideNav == 'dark-mode','light-mode':$store.state.NightMode.sideNav == 'light-mode'}">
        <v-tooltip bottom>
          <v-badge slot="activator" color="red" overlap>
            <span slot="badge" v-if="budgetDataBadge !== ''"> {{ budgetDataBadge }} </span>
            <v-icon>assessment</v-icon>
          </v-badge>
          <span>Budget Data</span>
        </v-tooltip>
      </v-tab>-->
      <!--Budget Data-->

      <!--Notification-->
      <!--<v-tab :href="'#Notifications'" :class="{'dark-mode':$store.state.NightMode.sideNav == 'dark-mode','light-mode':$store.state.NightMode.sideNav == 'light-mode'}">
        <v-tooltip bottom>
          <v-badge slot="activator" color="red" overlap>
            <span slot="badge" v-if="notificationBadge !== ''"> {{ notificationBadge }} </span>
            <v-icon>alarm</v-icon>
          </v-badge>
          <span> Reminder </span>
        </v-tooltip>
      </v-tab>-->

      <!-- Main Menu Tab item-->
      <v-tab-item :value="'MainMenu'">
        <v-list dense shaped :class="{'dark-mode':$store.state.NightMode.sideNav == 'dark-mode','light-mode':$store.state.NightMode.sideNav == 'light-mode'}">
            <template v-for="item in items">
                <!--<v-layout row
      v-if="item.heading"
      align-center
      :key="item.heading">
      <v-flex xs6>
        <v-subheader v-if="item.heading">
          {{ item.heading }}
        </v-subheader>
      </v-flex>
      <v-flex xs6 class="text-xs-center">
        <a href="#!" class="body-2 black--text">EDIT</a>
      </v-flex>
    </v-layout>
    -->

                <v-list-group v-if="item.children"
                              v-model="item.model"
                              :key="item.text"
                              :prepend-icon="item.model ? item.icon : item['icon-alt']"
                              :append-icon="item.model ? item.iconend : item['iconend-alt']">
                    <v-list-item slot="activator">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-for="(child, i) in item.children"
                                 :key="i"
                                 :to="child.link"
                                 @click="">
                        <v-list-item-action v-if="child.icon" class="text-xs-center">
                            <v-icon>{{ child.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ child.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-item v-else @click="" :to="item.link" :key="item.text">
                    <v-list-item-action class="text-xs-center">
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item style="padding-top: 20px; position: fixed; bottom: 0;">
                    <v-list-item-content>
                        <v-list-item-subtitle>Created By: <a href="https://codekonstruct.com/" target="_blank">CodeKonstruct</a></v-list-item-subtitle>
                        <v-list-item-subtitle>Version: {{version}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
          
        </v-list>
      </v-tab-item>
      <!-- /Main Menu Tab item-->

      <!-- Extensions Tab item-->
      <v-tab-item :value="'Extensions'">
        <template>
          <v-container fill-height>
            <v-layout justify-center align-center>
              <v-flex shrink>
                <span> No Extensions </span>
              </v-flex>
            </v-layout>
          </v-container>
        </template>
      </v-tab-item>
      <!-- /Extensions Tab item-->

      <!-- Budget Data Tab item-->
      <v-tab-item :value="'BudgetData'">
        <template>
          <v-container fill-height>
            <v-layout justify-center align-center>
              <v-flex shrink>
                <span> No Statistics </span>
              </v-flex>
            </v-layout>
          </v-container>
        </template>
      </v-tab-item>
      <!-- /Budget Data Tab item-->

      <!-- Notifications Tab item-->
      <v-tab-item :value="'Notifications'">
        <template>
          <v-container fill-height>
            <v-layout justify-center align-center>
              <v-flex shrink>
                <span> No new Notifications </span>
              </v-flex>
            </v-layout>
          </v-container>
        </template>
      </v-tab-item>
      <!-- /Notifications Tab item-->

    </v-tabs>
    <!-- /Side Navigation Tabs -->
  </v-card>

  
</template>

<script>
  import { sharedJS } from './sharedJS.js'
    //import { version } from '../../version.js'
    import pjson from '../../../package.json'

  export default {
    props: ['drawer'],
    data() {
      return {

        mainMenuBadge: '2',
        extensionsBadge: '3',
        budgetDataBadge: '1',
        notificationBadge: '2',

        navSelection: 'home',

        //side nav items
        items: [],
        version: ''

      }
    },
    beforeMount() {

      //Get Side Nav Items From SharedJS
      this.items = sharedJS.mainMenuItems().items
      this.version = pjson.version
      
    },
  }
</script>


<style scoped>
  .v-tabs__container {
    height: 0 !important;
  }
</style>
